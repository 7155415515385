<template>
    <div class="process-loading-container">
        <div class="process-loading-icon" />
        <div class="message">
            {{ message }} &nbsp;<span>.</span><span>.</span><span>.</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Processing',
    props: {
        message: {
            type: String,
            default: '資料更新中'
        }
    }
}
</script>

<style lang="scss" scoped>
.process-loading-container {
    margin: 100px 0;
}
.process-loading-icon {
    width: 100px;
    height: 100px;
    border-width: 8px;
    margin: 0 auto 20px auto;
}
.message {
    font-size: 15px;
    color: $secondary-grey;
    text-align: center;
}

.message span {
    font-size: 24px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    &:nth-child(2) {
        animation-delay: 0.2s;
    }

    &:nth-child(3) {
        animation-delay: 0.4s;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}
</style>
