<template>
    <div>
        <Step :steps="steps" class="step-container">
            <template v-slot:0>
                <div class="last-update-time">
                    <div v-text="lastUpdateTime || `讀取中...`" />
                    <div
                        v-if="
                            $hasPermission('canSyncPks') ||
                            $hasPermission('syncPksAPI')
                        "
                        v-show="lastUpdateTime"
                        class="info-icon"
                        @click.prevent="isShowPksUpdateTime = true"
                        @mouseenter="
                            !$isMobile() ? (isShowPksUpdateTime = true) : null
                        "
                    />
                </div>
                <AdminPksUpdateTime
                    :pksSyncStatus="pksSyncStatus"
                    v-model="isShowPksUpdateTime"
                />
            </template>
        </Step>
        <ViewTitle :title="`發佣作業 | ${title}`" class="viewtitle-container">
            <template v-slot:buttons>
                <div class="component-container button-container">
                    <AdminStartCalc
                        ref="AdminStartCalc"
                        :mType="mType"
                        :pksSyncStatus="pksSyncStatus"
                        :fileGenerateStatus="fileGenerateStatus"
                        :isPksSyncAvailable="isPksSyncAvailable"
                        :isGenerateAvailable="isGenerateAvailable"
                        :isCommissionOpen="isCommissionOpen"
                        :isInitSyncPks="isInitSyncPks"
                        @update-sync-status="setPksSyncStatus"
                    />
                    <Button
                        v-if="$hasPermission('downloadAdmin')"
                        buttonWord="全佣金明細表"
                        buttonStyle="hollow"
                        :disabled="isCommissionOpen"
                        @click="downloadAdminZip"
                    />
                </div>
            </template>
        </ViewTitle>
        <CommissionFilter
            mode="admin"
            :listData="listData"
            :isLoading="isLoadingTable"
            v-model:filterPayloadProp="filterPayload"
            v-model:filterLogProp="filterLog"
            @show-data-table="showDataTable"
            class="admin-filter"
        />
        <AdminController
            :mType="mType"
            :isPksSyncAvailable="isPksSyncAvailable"
            :isGenerateAvailable="isGenerateAvailable"
            :isCommissionOpen="isCommissionOpen"
            :isCommissionClosed="isCommissionClosed"
            :isCommissionVisible="isCommissionVisible"
            v-model:commissionStatus="commissionStatus"
        />
        <div class="table-remark margin-bottom-15">
            <ZipPasswordAlert />
        </div>
        <CommissionTable
            v-if="isPksSyncAvailable && isGenerateAvailable"
            :mTypeList="mTypeList"
            :listData="listData"
            :filterLog="filterLog"
            :isLoading="isLoadingTable"
            :canDownloadZip="
                !isCommissionOpen && $hasPermission('downloadSales')
            "
        />
        <Processing v-else />
        <div class="table-remark">
            <div
                class="last-update-time"
                :class="{ loading: isPksSyncAvailable && lastUpdateTime }"
                v-text="
                    `最後更新日期：${
                        isPksSyncAvailable && lastUpdateTime
                            ? lastUpdateTime
                            : '計算中...'
                    }`
                "
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Step from '@/components/Step.vue'
import Button from '@/components/Button.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import Processing from '@/components/Processing.vue'
import CommissionFilter from '@/containers/commission/CommissionFilter.vue'
import CommissionTable from '@/containers/commission/CommissionTable.vue'
import AdminStartCalc from '@/containers/commission/AdminStartCalc.vue'
import AdminController from '@/containers/commission/AdminController.vue'
import ZipPasswordAlert from '@/containers/commission/ZipPasswordAlert.vue'
import AdminPksUpdateTime from '@/containers/commission/AdminPksUpdateTime.vue'
import {
    getCommissionMTypeAPI,
    getCommissionPreClosingAPI,
    syncPksListAPI,
    canSyncPksAPI,
    downloadAdminZipAPI
} from '@/assets/javascripts/api'
import { computed } from 'vue'

export default {
    name: 'CommissionAdmin',
    components: {
        Step,
        Button,
        ViewTitle,
        Processing,
        CommissionFilter,
        CommissionTable,
        AdminStartCalc,
        AdminController,
        ZipPasswordAlert,
        AdminPksUpdateTime
    },
    methods: {
        showDataTable: async function () {
            if (!this.$hasPermission('list')) {
                this.$showErrorMessage(
                    '此帳號尚無查看佣金列表的權限',
                    '請確認您的帳號權限，如有疑問請洽飛鴿'
                )
                return
            }
            this.isLoadingTable = true
            try {
                /* Get Table Data */
                const payload = {
                    mType: this.mType
                }
                const res = await getCommissionPreClosingAPI(payload)
                this.listData = _.cloneDeep(res.data.dataSource)
                this.commissionStatus = res.data.status
            } catch (error) {
                this.listData = []
                this.commissionStatus = ''
                this.$showErrorMessage(
                    '查詢時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.isLoadingTable = false
            }
        },
        downloadAdminZip: async function () {
            if (!this.$hasPermission('downloadAdmin')) {
                this.$showErrorMessage(
                    '此帳號尚無下載全佣金明細表的權限',
                    '請確認您的帳號權限，如有疑問請洽飛鴿'
                )
                return
            }
            this.$setGaEvent('downloadAdminZip', 'click-Button')
            const payload = {
                mType: this.mType,
                receiveInfos: this.filterPayload.period.map((period) => {
                    return { period: Number(period) }
                })
            }
            this.$showLoading()
            try {
                const res = await downloadAdminZipAPI(payload)
                this.$downloadZipHelper(res, 'zipUrl')
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                const fileError = error.response?.data?.displayMsg || '未知錯誤'
                const errorTitle = '下載全佣金明細表檔案時發生錯誤'
                const msg = `${fileError}</br>若持續出現此問題，請聯繫資訊客服`
                this.$showErrorMessage(errorTitle, msg)
            } finally {
                this.$hideLoading()
            }
        },
        setMType: async function () {
            try {
                const res = await getCommissionMTypeAPI()
                this.mTypeList = res.data?.mTypeList
            } catch (error) {
                this.mTypeList = []
                this.$showErrorMessage(
                    '頁面載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            }
        },
        setPksSyncStatus: async function (isShowCoverLoading = false) {
            if (
                !this.$hasPermission('canSyncPks') ||
                !this.$hasPermission('syncPksAPI')
            ) {
                this.$showErrorMessage(
                    '此帳號尚無檢視PKS同步狀態的權限',
                    '請確認您的帳號權限，如有疑問請洽飛鴿'
                )
                return
            }

            try {
                // long polling的不顯示滿版Loading，從計算佣金資料觸發的則會顯示
                if (isShowCoverLoading) this.$showLoading()
                const [resCanSyncPks, resSyncPksList] = await Promise.all([
                    canSyncPksAPI(),
                    syncPksListAPI()
                ])

                if (JSON.stringify(resSyncPksList.data) === '{}') {
                    // 後端壞掉會回 {} 而不是 []，前端幫忙防呆
                    this.pksSyncStatus = []
                } else {
                    this.pksSyncStatus = resSyncPksList.data ?? []
                }
                this.fileGenerateStatus = resCanSyncPks.data ?? {}
            } catch {
                this.$showErrorMessage(
                    '取得PKS同步或計算佣金狀態時出現錯誤',
                    '請稍後再試，若持續出現此問題，請聯繫資訊客服'
                )
                this.pksSyncStatus = []
                this.fileGenerateStatus = []
            } finally {
                this.setPksUpdateTime()
                if (isShowCoverLoading) this.$hideLoading()
            }
        },
        setPksUpdateTime: function () {
            const lastUpdateTime = this.pksSyncStatus
                .map((item) => new Date(item.startAt).getTime())
                .reduce((memo, date) => Math.max(memo, date), 0)
            this.lastUpdateTime = this.$toLocalString(lastUpdateTime)
        },
        checkSyncStatus: async function () {
            if (
                !this.$hasPermission('canSyncPks') ||
                !this.$hasPermission('syncPksAPI')
            ) {
                this.$showErrorMessage(
                    '此帳號尚無檢視PKS同步狀態的權限',
                    '請確認您的帳號權限，如有疑問請洽飛鴿'
                )
                return
            }
            const isShowCoverLoading = true
            await this.setPksSyncStatus(isShowCoverLoading)
            this.isInitSyncPks = false

            // long polling 每20秒問一次
            this.syncStatusIntervalId = window.setInterval(() => {
                this.setPksSyncStatus()
            }, 1000 * 60 * 5)
        }
    },
    computed: {
        title: function () {
            const mType = this.mTypeList?.find((m) => m.mType == this.mType)
            return mType?.name ?? ''
        },
        mType: function () {
            return this.mTypeParam === 'ls' ? '1000090' : '1000091'
        },
        mTypeParam: function () {
            return Array.isArray(this.$route.params.mType)
                ? this.$route.params.mType[0]
                : this.$route.params.mType
        },
        steps: function () {
            let currentStep = 1
            if (this.isCommissionOpen) currentStep = 2
            if (this.isCommissionClosed) currentStep = 3
            if (this.isCommissionVisible) currentStep = 4
            return {
                current: currentStep,
                labels: ['PKS更新', '佣金計算', '停止更新', '開放業務員查詢']
            }
        },
        isCommissionOpen: function () {
            return this.commissionStatus === 'open'
        },
        isCommissionClosed: function () {
            return this.commissionStatus === 'closed'
        },
        isCommissionVisible: function () {
            return this.commissionStatus === 'visible'
        },
        isPksSyncAvailable: function () {
            const pksSyncFinish = Object.values(this.pksSyncStatus).every(
                (task) => !task.syncIsProcessing
            )
            return pksSyncFinish
        },
        isGenerateAvailable: function () {
            return this.fileGenerateStatus.isAvailable
        }
    },
    data() {
        return {
            isLoadingTable: true,
            isInitSyncPks: true,
            filterLog: {},
            filterPayload: {},
            listData: [],
            mTypeList: [],
            // PksSync && 產檔狀態
            pksSyncStatus: [],
            fileGenerateStatus: {},
            syncStatusIntervalId: '',
            // PksSync同步時間視窗
            isShowPksUpdateTime: false,
            lastUpdateTime: '',
            // 開關帳狀態
            commissionStatus: 'open'
        }
    },
    provide() {
        return {
            mTypeTitle: computed(() => this.title)
        }
    },
    async mounted() {
        this.setMType()
        this.checkSyncStatus()
    },
    beforeUnmount() {
        window.clearInterval(this.syncStatusIntervalId)
    }
}
</script>

<style lang="scss" scoped>
.viewtitle-container {
    :deep(.main-header-word) {
        @media screen and (max-width: 576px) {
            margin-bottom: 20px;
        }
    }
}

.component-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 576px) {
        width: 100%;

        .step-button {
            width: 45%;
        }
    }
}

:deep(.step-container) {
    .flex:nth-child(1) {
        .step-content {
            margin-right: 0;
            @media screen and (max-width: 576px) {
                margin-right: -15px;
            }
        }
    }
}
.last-update-time {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    color: $secondary-grey;
    text-align: right;
    min-height: 20px;

    @media screen and (max-width: 576px) {
        text-align: center;
    }
}

.table-remark {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    color: $secondary-grey;
    font-size: 14px;
    .last-update-time {
        justify-content: right;
    }

    @media screen and (max-width: 576px) {
        flex-wrap: wrap;
    }
}

.info-icon {
    display: inline-flex;
    min-width: 16px;
    height: 16px;
    margin-left: 5px;
    @media screen and (max-width: 576px) {
        flex-wrap: wrap;
    }
}

.admin-filter {
    :deep(#component-area) {
        z-index: 4;
    }
}
</style>
